.ant-steps-item-finish
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
  background: #ff000082;
  width: 20px;
  height: 20px;
  border: 1px solid #80808016;
}

.ant-steps-item-wait
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
  background: #ffeb9a;
  width: 20px;
  height: 20px;
  border: 1px solid #80808016;
}

.ant-steps-item-process
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
  background: #ffffff;
  width: 20px;
  height: 20px;
  border: 1px solid #54514c;
}

.ant-steps-item-finish
> .ant-steps-item-container
> .ant-steps-item-tail::after {
  background-color: #f2890b;
}

.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-tail::after {
  background-color: #54514c;
}

.ant-steps-item-wait
> .ant-steps-item-container
> .ant-steps-item-tail::after {
  background-color: #54514c;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -7.5px;
  left: -5px;
}

