.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6e6e6 !important;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #000000;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    span {
        border: 1px solid #000000;
        border-radius: 10px;
        color: #000000;
        background: transparent;
    }
}

.ant-menu-item a:hover {
    color: #0000008e;
}

.ant-menu-submenu-popup {
    transform: translateY(-10px);
    border-radius: 8px;
    li {
        margin: 9px 0;
    }
    .ant-menu-sub,
    .ant-menu-submenu,
    .ant-menu-item {
        a {
            font-size: 20px;
            padding: 17px 4px;
            font-weight: 600;

        }
    }
}
